<template>
  <svg aria-hidden="true">
    <use :href="symbolId" :fill="color" />
  </svg>
</template>

<script setup>
import { computed, toRefs } from 'vue';

const props = defineProps({
  prefix: {
    type: String,
    default: 'icon',
  },
  name: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    default: '#333',
  },
});

const { prefix, name } = toRefs(props);

const symbolId = computed(() => `#${prefix.value}-${name.value}`);
</script>

<style scoped></style>
