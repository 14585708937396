import { createApp } from 'vue';
import { pinia } from './stores/pinia.js';
import App from './App.vue';
import { router } from './router';
import 'virtual:svg-icons-register';
import VueTablerIcons from 'vue-tabler-icons';
import { VueQueryPlugin } from '@tanstack/vue-query';

import './index.css';
import 'devextreme/dist/css/dx.light.css';
import { loading } from './helpers/loading';
import queryClient from './composables/queryClient';

const app = createApp(App);
app.use(VueTablerIcons);
app.directive('loading', loading);
app.use(VueQueryPlugin, {
  queryClient,
  enableDevtoolsV6Plugin: true,
});
app.use(router).use(pinia);
app.mount('#app');
