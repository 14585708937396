export default {
  'xl-max': 2560,
  'xl-mid': 1920,
  'xl-min': 1440,
  'lg-max': 1439,
  'lg-min': 1280,
  'md-max': 1279,
  'md-min': 1024,
  'sm-max': 1023,
  'sm-min': 768,
  'xs-max': 767,
  'xs-min': 640,
  '2xs-max': 639,
  '2xs-min': 480,
  '3xs-max': 479,
  '3xs-min': 380,
  '4xs-max': 379,
  '4xs-min': 0,
};
