import loaderIcon from '@/assets/icon/i_loader_black.png';
export const loading = {
  app: document.getElementById('app'),
  parent: null,

  insertLoader() {
    loading.parent.style.position = 'relative';
    loading.parent.insertAdjacentHTML(
      'beforeend',
      `<div class="loader js-loader">
        <div class="loader__icon">
            <img src="${loaderIcon}" loading="lazy" decoding="async" width="" height="" alt="" title="">
        </div>
    </div>`,
    );
  },

  removeLoader(loader) {
    loading.parent.style.position = '';
    loading.parent.removeChild(loader);
  },

  mounted(el, binding) {
    if (el.children.length) {
      loading.parent = el;
    } else {
      loading.parent = loading.app;
    }

    if (binding.value) {
      loading.insertLoader();
    }
  },

  updated(el, binding) {
    if (el.children.length) {
      if (loading.parent === loading.app) {
        const loader = loading.parent.querySelector(':scope > .js-loader');

        loading.removeLoader(loader);
      }
      loading.parent = el;
    }

    const loader = loading.parent.querySelector(':scope > .js-loader');

    if (binding.value && !loader) {
      loading.insertLoader();
    } else if (!binding.value && loader) {
      loading.removeLoader(loader);
    }
  },

  unmounted() {
    const loader = loading.parent.querySelector(':scope > .js-loader');
    if (loader) {
      loading.removeLoader(loader);
    }
  },
};
