import { defineStore } from 'pinia';
import { requestErrorHandler } from '../helpers/requestErrorHandler';
import { getCollections, getCollection, uploadFile, addShopImage, removeShopImage, newDesignItem, removeDesignItem, sendToApprove, sendToApproveTask, copyDesignTask, removeComment } from '../api';
import { useStorage } from '@vueuse/core';
const showTestUsers = useStorage('showTestUsers', false);
import queryClient from '../composables/queryClient';

const filterCollectionsByState = (collections, state, search) => {
  const byState = collections.filter((el) => {
    if (!search) return el.state === state;
    return el.state === state && ((el.name ? el.name : '').toLowerCase().includes(search.toLowerCase()) || el.profiles.email.toLowerCase().includes(search.toLowerCase()) || el.id.toString().includes(search.toLowerCase()));
  });
  return byState;
};

export const useCollectionStore = defineStore('collection', {
  state: () => ({
    loading: true,
    collection: {},
    collections: [],
    search: null,
  }),
  getters: {
    getCollections: (state) => state.collections,
    briefing: (state) => filterCollectionsByState(state.collections, 'Briefing', state.search),
    designing: (state) => filterCollectionsByState(state.collections, 'Designing', state.search),
    wfa: (state) => filterCollectionsByState(state.collections, 'Waiting for approval', state.search),
    approved: (state) => filterCollectionsByState(state.collections, 'Approved', state.search),
  },
  actions: {
    setCollection(collection) {
      this.collection = collection;
    },
    async fetchCollections() {
      try {
        const collections = await getCollections();
        if (!showTestUsers.value) {
          this.collections = collections.filter((el) => !el.profiles.user_type);
        } else {
          this.collections = collections;
        }
      } catch (error) {
        return requestErrorHandler(error);
      }
    },
    async fetchCollection(id) {
      try {
        //this.collection = await getCollection(id);
        queryClient.invalidateQueries({ queryKey: ['collection', id] });
      } catch (error) {
        return requestErrorHandler(error);
      }
    },
    async uploadFile(file) {
      try {
        let data = new FormData();
        data.append('file', file);
        const file_id = await uploadFile(data);
        return file_id.id;
      } catch (error) {
        requestErrorHandler(error);
      }
    },
    async addShopImage(id, file, collection_id) {
      try {
        const file_id = await this.uploadFile(file);
        await addShopImage(id, file_id);
        await this.fetchCollection(this.collection.id);
      } catch (error) {
        requestErrorHandler(error);
      }
    },
    async addDesignItem(task_id, file) {
      try {
        const file_id = await this.uploadFile(file);
        await newDesignItem(task_id, file_id);
        await this.fetchCollection(this.collection.id);
      } catch (error) {
        return requestErrorHandler(error.body);
      }
    },
    async removeDesignItem(id) {
      try {
        await removeDesignItem(id);
        await this.fetchCollection(this.collection.id);
      } catch (error) {
        requestErrorHandler(error.body);
      }
    },
    async removeShopImage(id, file_id, collection_id) {
      try {
        await removeShopImage(id, file_id);
        await this.fetchCollection(this.collection.id);
      } catch (error) {
        requestErrorHandler(error.body);
      }
    },
    async sendApproveCollection(id) {
      try {
        await sendToApprove(id);
        await this.fetchCollection(this.collection.id);
      } catch (error) {
        return requestErrorHandler(error.body);
      }
    },
    async sendApproveTask(id) {
      try {
        await sendToApproveTask(id);
        await this.fetchCollection(this.collection.id);
      } catch (error) {
        return requestErrorHandler(error.body);
      }
    },
    async copyDesignTask(id) {
      try {
        await copyDesignTask(id);
        await this.fetchCollection(this.collection.id);
      } catch (error) {
        return requestErrorHandler(error.body);
      }
    },
    async removeComment(id) {
      try {
        await removeComment(id);
        await this.fetchCollection(this.collection.id);
      } catch (error) {
        return requestErrorHandler(error.body);
      }
    },
  },
});
