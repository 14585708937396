<script setup>
import SvgIcon from './SvgIcon.vue';
import { reactive, watch } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const navData = reactive([
  {
    icon: 'home-icon',
    name: 'Home',
    link: '/',
  },
  {
    icon: 'user-icon',
    name: 'users',
    link: '/users_page',
  },
  // {
  //   icon: 'truck-delivery-icon',
  //   name: 'Orders',
  //   link: '/orders',
  // },
  {
    icon: 'truck-delivery-icon',
    name: 'Orders',
    link: '/orders',
  },
  {
    icon: 'layout-collage-icon',
    name: 'Designs',
    link: '/designs',
  },
  {
    icon: 'cube-send-icon',
    name: 'Shipments',
    link: '/shipments',
  },
  {
    icon: 'file-dollar-icon',
    name: 'Invoices',
    link: '/invoices',
  },
  // {
  //   icon: 'box-seam-icon',
  //   name: 'Delivery',
  //   link: '/delivery',
  // },
]);

watch(route, (newRoute) => {
  navData.forEach((item) => {
    if (newRoute.path.includes(item.link)) {
      item.open = true;
    } else {
      item.open = false;
    }
  });
});
</script>

<template>
  <nav class="custom-scrollbar w-full shrink-0 overflow-y-auto border-t border-t-light_gray bg-white xl:overflow-y-hidden xl:border-r xl:border-t-0 xl:border-r-light_gray xl:pl-8 hover:xl:overflow-y-auto">
    <div class="sticky top-0 z-20 hidden h-[42px] flex-col items-baseline justify-center bg-white pt-[26px] xl:flex">
      <RouterLink to="/">
        <SvgIcon name="logo" class="h-4 w-[106px]" />
      </RouterLink>
    </div>

    <div id="navMenu" class="flex overflow-y-auto py-[12px] xl:my-[10px] xl:block xl:py-[10px]" data-cy="nav-menu">
      <div v-for="item in navData" :key="item.name" class="flex w-1/5 justify-center xl:block xl:w-full">
        <RouterLink :to="{ path: item.link }" active-class="active-link" class="flex flex-col items-center leading-none hover:underline xl:min-h-[40px] xl:flex-row xl:py-[6px]">
          <div class="icon mb-2 shrink-0 text-[#F1F1F1] xl:mb-0 xl:mr-2">
            <component :is="item.icon" class="h-[22px] w-[22px]" />
          </div>
          <div v-if="item.name" class="text-small-h4 xl:text-desktop-h5 xl:uppercase">{{ item.name }}</div>
        </RouterLink>
      </div>
    </div>
  </nav>
</template>

<style lang="scss">
@import '@/assets/styles/helpers/mixin';
@import '@/assets/styles/helpers/variables';

.active-link {
  > .icon {
    color: $color-primary-orange;
  }

  > i.icon {
    background: $color-primary-orange;
  }
}
.el-popper.is-dark {
  padding: 16px 24px;
  background: #000000;
  border-radius: 12px;
  border: 0;
  font-size: 11px;
  line-height: 16px;
}
</style>
