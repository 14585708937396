<script setup>
import { RouterView, useRoute } from 'vue-router';
import { computed, onMounted } from 'vue';
import { useAuthStore } from './stores/auth.store';

const route = useRoute();
const layout = computed(() => (route.meta.layout ? route.meta.layout : null));
const authStore = useAuthStore();
onMounted(() => {
  authStore.handleSupabaseEvents();
});
</script>

<template>
  <Suspense>
    <template #fallback>
      <div class="w-full grow">
        <div class="loader js-loader">
          <div class="loader__icon" />
        </div>
      </div>
    </template>

    <component v-if="layout" :is="layout">
      <RouterView />
    </component>
  </Suspense>
</template>

<style lang="scss">
@import '@/assets/styles/styles.scss';

#app {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  position: fixed;
  top: env(safe-area-inset-top);
  right: env(safe-area-inset-right);
  bottom: env(safe-area-inset-bottom);
  left: env(safe-area-inset-left);
}

::selection {
  background: #2c80e7b6;
}

::-moz-selection {
  background: #2c80e7b6;
}

input[type='email'],
input[type='password'],
input[type='text'],
input[type='number'],
input {
  -webkit-appearance: none;
}

input {
  font-size: 16px;
  border-radius: 0;

  &:focus {
    outline: none;
  }
}
</style>
