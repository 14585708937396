import { defineStore } from 'pinia';

import { getOnlineUsers } from '../api';
import { requestErrorHandler } from '../helpers/requestErrorHandler';

export const useCommonStore = defineStore({
  id: 'common',
  state: () => ({
    pageTitle: '',
    pageSubTitle: '',
    online: [],
  }),
  actions: {
    async getOnlineUsers() {
      try {
        const data = await getOnlineUsers();
        this.online = data;
      } catch (error) {
        return requestErrorHandler(error);
      }
    },
  },
});
