<script setup>
import TheHeader from '../components/TheHeader.vue';
import TheNavigation from '../components/TheNavigation.vue';
import { viewport } from '@/mixins/viewport-mixine';
import { ref } from 'vue';
import { useRoute } from 'vue-router';

const stickyNavRef = ref(null);
const route = useRoute();
</script>

<template>
  <div class="flex min-h-0 grow">
    <div class="flex shrink-0 xl:w-[215px]">
      <teleport v-if="stickyNavRef" :to="stickyNavRef" :disabled="!['md', 'sm', 'xs'].includes(viewport)">
        <TheNavigation />
      </teleport>
    </div>
    <div id="body" class="flex min-w-0 grow flex-col overflow-y-auto">
      <TheHeader class="sticky top-0 z-50 shrink-0" />
      <main class="flex grow flex-col">
        <slot />
      </main>
      <div v-show="!['Checkout', 'GalleryItem', 'Cart', 'StaffGroup'].includes(route.name)" ref="stickyNavRef" class="sticky bottom-0 z-30" />
    </div>
  </div>
</template>

<style lang="scss">
#app {
  overflow: visible;
}
</style>
