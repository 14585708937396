import { mande } from 'mande';

const collections = mande('/api/v2/backoffice/collections');

export function getCollections() {
  return collections.get();
}

export function getCollection(id) {
  return collections.get(id);
}

export function sendToApprove(id) {
  return collections.get(`/send_to_approve/${id}`);
}

export function sendToApproveTask(id) {
  return collections.get(`/send_to_approve_task/${id}`);
}

const files = mande('/api/v2/files', { headers: { 'Content-Type': undefined } });

export function uploadFile(file) {
  return files.post(file);
}

const designItems = mande('/api/v2/backoffice/design_task');

export function newDesignItem(task_id, file_id) {
  return designItems.post({ task_id, file_id });
}

export function removeDesignItem(id) {
  return designItems.delete(id);
}

export function addShopImage(id, file_id) {
  return designItems.post(`/add_shop_image/${id}`, { file_id });
}
export function removeShopImage(id, file_id) {
  return designItems.delete(`/remove_shop_image/${id}/${file_id}`);
}

export function copyDesignTask(id) {
  return designItems.get(`/copy_task/${id}`);
}

export function updateTask(id, { color }) {
  return designItems.put(id, { color });
}

const users = mande('/api/v2/backoffice/users');

export function getAllUsers() {
  return users.get();
}
export function getOnlineUsers() {
  return users.get('/online');
}

export function updateUser(id, user_type) {
  const type = user_type;
  return users.put(id, { user_type: type });
}

export function getUserLoginLink(email) {
  return users.post('/get_login_link', { email });
}

const shipments = mande('/api/v2/backoffice/shipments');

export function getAllShipments() {
  return shipments.get();
}
export function getOrderShipments(id) {
  return orders.get(`/${id}/shipments`);
}
export function getOrderShipmentsRates(id) {
  return orders.get(`/${id}/shipments/rates`);
}

export function getOrderStaff(id) {
  return orders.get(`/${id}/staff`);
}

export function setTracking(id, tracking, shippingMethod, shipping_date, delivery_date, state, payment_state) {
  return shipments.put(id, { tracking, shippingMethod, shipping_date, delivery_date, state, payment_state });
}

const orders = mande('/api/v2/backoffice/orders');

export function getAllOrders() {
  return orders.get();
}

export function getOrder(id) {
  return orders.get(id);
}

export function updateOrder(id, state, delivery_date) {
  return orders.put(id, { state, delivery_date });
}
export function updateOrderLink(id, external_link) {
  return orders.put(`${id}/link`, { external_link });
}

const companies = mande('/api/v2/backoffice/companies');

export function getAllCompanies() {
  return companies.get();
}

const events = mande('/api/v2/backoffice/events');

export function getEvents() {
  return events.get();
}

const invoices = mande('/api/v2/backoffice/invoices');

export function getInvoices(company_id) {
  return invoices.get(`/${company_id}`);
}

export function addInvoice(invoice) {
  return invoices.post(`/${invoice.company_id}`, invoice);
}
export function removeInvoice(id) {
  return invoices.delete(id);
}

export function updateInvoice(invoice) {
  return invoices.put(`/${invoice.id}`, invoice);
}

const profile = mande('/api/v2/profile');

export function setPresense() {
  return profile.get('/presense');
}

const comments = mande('/api/v2/backoffice/comments');

export function removeComment(id) {
  return comments.delete(id);
}
